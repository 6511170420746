// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "resources/assets/bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

@CHARSET "UTF-8";

.box {
 max-width:1024px;
 max-height:768px;
 -webkit-border-radius: 10px;
 border-radius: 10px;  background-image: url("/img/chicks_bg.png");
 background-repeat: repeat-y;
 background-size: cover;
 padding:20px;
}

.snap {
  width: 660px;
  height: 510px;
  padding: 10px 10px 20px 10px;
  background-color:white;
  margin:0 auto;
  border-radius: 5px;
}

.snap img {
  border-radius: 5px;
}

.date {
  text-align: center;
  font-size:small;
}

body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        color: #000;
        background-color: #EEE;
        display: table;
        font-weight: 300;
        font-family: 'Lato';
}
